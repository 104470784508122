import { render, staticRenderFns } from "./reader.vue?vue&type=template&id=388001ea&scoped=true&"
import script from "./reader.vue?vue&type=script&lang=js&"
export * from "./reader.vue?vue&type=script&lang=js&"
import style0 from "./reader.vue?vue&type=style&index=0&id=388001ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388001ea",
  null
  
)

export default component.exports