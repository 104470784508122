var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"reader",staticClass:"reader oepnX"},[(!_vm.isLoading)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"atlas",on:{"click":function($event){_vm.popupShow = true}}},[_c('van-swipe',{ref:"swipe",staticClass:"swipe",attrs:{"loop":false,"indicator-color":"white"},on:{"change":_vm.switchSwipe},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"swipeIndicator",class:{ swipeIndicatorDaytime: !_vm.isNighttime }},[_vm._v(" "+_vm._s(_vm.switchSwipeIndex)+"/"+_vm._s(_vm.chapterList.length - 2)+" ")])]},proxy:true}],null,false,347432452)},_vm._l((_vm.chapterList),function(chapter,chapterIndex){return _c('van-swipe-item',{key:chapterIndex},[_c('div',{staticClass:"swipeContent",class:{ daytime: !_vm.isNighttime }},[(_vm.switchSwipeIndex == 1)?_c('div',{staticClass:"readerTitle"},[_c('span',[_vm._v(" "+_vm._s(chapter.name)+" ")])]):_vm._e(),_c('div',{staticClass:"readerContent"},_vm._l((chapter.paragraph),function(item,index){return _c('p',{key:index,class:{ retract: item.ret }},[_vm._v(" "+_vm._s(item.text)+" ")])}),0),(_vm.advInfo && chapterIndex == _vm.chapterList.length - 2)?_c('div',{staticClass:"advertising"},[_c('van-swipe',{staticClass:"swipe",attrs:{"autoplay":3000,"indicator-color":"#FECD55"}},_vm._l((_vm.advInfo),function(item,index){return _c('van-swipe-item',{key:index,on:{"click":function($event){return _vm.goAdv(item)}}},[_c('ImgDecypt',{staticClass:"advImg",attrs:{"src":item.cover}})],1)}),1)],1):_vm._e()])])}),1)],1)]):_c('Loading'),_c('van-overlay',{attrs:{"show":_vm.popupShow},on:{"click":function($event){_vm.popupShow = false}}},[_c('van-popup',{style:({
          height: '44px',
          background: _vm.isNighttime ? 'rgb(44,44,44)' : 'rgb(255,255,255)',
        }),attrs:{"overlay":false,"position":"top"},model:{value:(_vm.popupShow),callback:function ($$v) {_vm.popupShow=$$v},expression:"popupShow"}},[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"headBar",class:{ headBarDay: !_vm.isNighttime, headBarNight: _vm.isNighttime }},[_c('div',{staticClass:"leftIcon",on:{"click":function($event){return _vm.$router.go(-1)}}},[(!_vm.isNighttime)?_c('img',{attrs:{"src":require("@/assets/png/left.png")}}):_c('img',{attrs:{"src":require("@/assets/png/leftwhite.png")}})]),_c('div',{staticClass:"headTitle",staticStyle:{"'justify-content'":"'left'"}},[_c('span',[_vm._v(_vm._s(_vm.chapterList.length > 0 ? _vm.chapterList[0].name : ""))]),_c('span',[_vm._v("第"+_vm._s(Number(_vm.itemIndex) + 1)+"章")])]),_c('div',{staticClass:"fictionSelect",on:{"click":_vm.onChapter}},[(_vm.isNighttime)?_c('img',{attrs:{"src":require("@/assets/png/fictionSelect.png")}}):_c('img',{attrs:{"src":require("@/assets/png/fictionSelect2.png")}})])])])]),_c('van-popup',{style:({
          height: '59px',
          background: _vm.isNighttime ? 'rgb(44,44,44)' : 'rgb(255,255,255)',
        }),attrs:{"overlay":false,"position":"bottom"},model:{value:(_vm.popupShow),callback:function ($$v) {_vm.popupShow=$$v},expression:"popupShow"}},[_c('div',{staticClass:"bottomBar",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"setModel"},[(_vm.isNighttime)?_c('div',{staticClass:"setModelBtn daytime",on:{"click":_vm.setModel}},[_c('img',{attrs:{"src":require("@/assets/png/daytime.png")}}),_c('span',[_vm._v("日间")])]):_c('div',{staticClass:"setModelBtn nighttime",on:{"click":_vm.setModel}},[_c('img',{attrs:{"src":require("@/assets/png/nighttime.png")}}),_c('span',[_vm._v("夜间")])])])])])],1),_c('van-popup',{style:({
        height: '100%',
        width: '210px',
        background: _vm.isNighttime ? 'rgb(44,44,44)' : 'rgb(255,255,255)',
      }),attrs:{"position":"right"},model:{value:(_vm.chapterShow),callback:function ($$v) {_vm.chapterShow=$$v},expression:"chapterShow"}},[(_vm.currentWorks)?_c('div',{staticClass:"chapterContent"},[_c('div',{staticClass:"chapterTitle",class:{
            chapterTitleDay: !_vm.isNighttime,
            chapterTitleNight: _vm.isNighttime,
          }},[_c('span',[_vm._v(_vm._s(_vm.currentWorks.title))])]),_c('div',{staticClass:"chapterDivision"},[_c('div',{staticClass:"name"},[_c('span',[_vm._v("共"+_vm._s(_vm.currentWorks.contents.length)+"章")])]),_c('div',{staticClass:"btn",on:{"click":_vm.chapterSort}},[_c('img',{attrs:{"src":require("@/assets/png/sort.png")}}),(_vm.sorting)?_c('span',[_vm._v("倒序")]):_c('span',[_vm._v("升序")])])]),_c('div',{staticClass:"chapterList"},_vm._l((_vm.currentWorks.contents),function(item,index){return _c('div',{key:index,staticClass:"chapterItem",on:{"click":function($event){return _vm.jumpWorks(index)}}},[_c('span',{class:{
                activeNameDay: index == _vm.itemIndex && !_vm.isNighttime,
                activeNameNighttime: index == _vm.itemIndex && _vm.isNighttime,
              }},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }